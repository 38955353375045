/* Autogenerated file. Do not modify */
define([], function() {
    'use strict';
    var skins = {};
     skins['wysiwyg.viewer.skins.TPAModalSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [],
      {
        "role": "dialog",
        "tabIndex": 0
      },
      [
        "div",
        "frameWrap",
        [],
        {},
        [
          "iframe",
          "iframe",
          [],
          {
            "allowFullScreen": true,
            "frameBorder": 0
          }
        ],
        [
          "div",
          "errorMessage",
          [],
          {}
        ],
        [
          "button",
          "xButton",
          [],
          {
            "title": "Close modal",
            "aria-label": "Close modal"
          }
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "bg-block": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "1px",
    "bg-block": "#555"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    }
  },
  "css": {
    "%": "position:fixed;width:100%;height:100%;z-index:100000;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;width:100%;height:100%;overflow:hidden;z-index:999;",
    "%[data-state~=\"mobile\"] %blockingLayer": "display:none;",
    "%[data-state~=\"mobile\"] %dialog": "position:fixed;top:0;left:0;width:100%;height:100%;margin:0;",
    "%dialog": "position:fixed;top:50%;left:50%;width:200px;height:200px;margin-top:-100px;margin-left:-100px;z-index:1000;",
    "%frameWrap": "overflow:hidden;background:#fff;width:100%;height:100%;position:relative;z-index:1001;border:1px solid #e8e2e2;",
    "%iframe": "position:absolute;width:100%;height:100%;[rd]",
    "%xButton": "position:absolute;z-index:1002;margin-top:4px;right:9px;font-family:courier;font-size:24px;font-weight:bold;cursor:pointer;color:#a1a1a1;",
    "%xButton:hover": "color:#373737;"
  }
}
 skins['wysiwyg.viewer.skins.TPAPopupSkin'] = {
  "react": [
    [
      "div",
      "frameWrap",
      [],
      {},
      [
        "div",
        "closeButton",
        [],
        {}
      ],
      [
        "iframe",
        "iframe",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "tdr": "URL"
  },
  "paramsDefaults": {
    "shd": "4px 4px 15px rgba(0, 0, 0, 0.4)",
    "rd": "3px",
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "background:#fff;overflow:hidden;z-index:100000;[shd]  [rd]",
    "%iframe": "position:absolute;width:100%;height:100%;z-index:1000;",
    "%closeButton": "position:absolute;z-index:1001;top:10px;right:10px;cursor:pointer;background:transparent url([tdr]popup_close_x.png) no-repeat right top;height:24px;width:24px;"
  }
}
 skins['wysiwyg.viewer.skins.TPAPreloaderSkin'] = {
  "react": [
    [
      "div",
      "content",
      [],
      {},
      [
        "div",
        "preloader",
        [],
        {}
      ],
      [
        "span",
        "loadingText",
        [],
        {}
      ]
    ]
  ],
  "css": {
    "%": "position:absolute;top:0;left:0;color:#373737;width:100%;height:100%;",
    "%content": "width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.TPASectionSkin'] = {
  "react": [
    [
      "iframe",
      "iframe",
      [],
      {}
    ],
    [
      "div",
      "overlay",
      [],
      {}
    ]
  ],
  "exports": {
    "preloaderOverlay": {
      "skin": "wysiwyg.viewer.skins.TPAPreloaderSkin",
      "styleGroup": "preloaderStyleGroup"
    },
    "unavailableMessageOverlay": {
      "skin": "wysiwyg.viewer.skins.TPAUnavailableMessageOverlaySkin",
      "styleGroup": "tpaUnavailableMessageOverlaySkinStyleGroup"
    }
  },
  "css": {
    "%iframe": "overflow:hidden;position:absolute;width:100%;height:100%;display:block;",
    "%[data-state~=\"iframeNotShown\"] %iframe": "display:none;",
    "%[data-state~=\"iframeRenderedInvisible\"] %iframe": "visibility:hidden;"
  }
}
 skins['wysiwyg.viewer.skins.TPAUnavailableMessageOverlaySkin'] = {
  "react": [
    [
      "div",
      "content",
      [],
      {},
      [
        "div",
        "iconContainer",
        [],
        {},
        [
          "img",
          "unavailableIcon",
          [],
          {}
        ]
      ],
      [
        "div",
        "textTitle",
        [],
        {}
      ],
      [
        "div",
        "textContainer",
        [],
        {},
        [
          "span",
          "text",
          [],
          {}
        ],
        [
          "a",
          "reloadButton",
          [],
          {}
        ],
        [
          "span",
          "dismissButton",
          [],
          {},
          [
            "a",
            null,
            [],
            {},
            "Dismiss"
          ]
        ]
      ]
    ]
  ],
  "css": {
    "%": "position:absolute;top:0;left:0;color:#373737;width:100%;height:100%;",
    "%content": "width:100%;height:100%;background:rgba(255, 255, 255, 0.9);font-size:0;margin-top:5px;",
    "%textContainer": "color:#373737;font-family:\"Helvetica Neue\", \"HelveticaNeueW01-55Roma\", \"HelveticaNeueW02-55Roma\", \"HelveticaNeueW10-55Roma\", Helvetica, Arial, sans-serif;font-size:14px;display:inline-block;vertical-align:middle;width:100%;margin-top:10px;text-align:center;",
    "%reloadButton": "display:inline-block;",
    "% a": "color:#0099FF;text-decoration:underline;cursor:pointer;",
    "%iconContainer": "display:none;",
    "%dismissButton": "display:none;",
    "%textTitle": "font-family:\"Helvetica Neue\", \"HelveticaNeueW01-55Roma\", \"HelveticaNeueW02-55Roma\", \"HelveticaNeueW10-55Roma\", Helvetica, Arial, sans-serif;display:none;",
    "%[data-state~=\"hideIframe\"] %_buttons": "opacity:1;",
    "%[data-state~=\"hideOverlay\"]": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.TPAWidgetNativeDeadcompSkin'] = {
  "react": [
    [
      "div",
      "content",
      [],
      {},
      [
        "div",
        "iconContainer",
        [],
        {},
        [
          "svg",
          null,
          [],
          {
            "width": "22px",
            "height": "23px",
            "viewBox": "0 0 22 23"
          },
          [
            "title",
            null,
            [],
            {},
            "!"
          ],
          [
            "g",
            "Page-1",
            [],
            {
              "stroke": "none",
              "strokeWidth": "1",
              "fill": "none",
              "fillRule": "evenodd"
            },
            [
              "g",
              "Error-Message",
              [],
              {
                "transform": "translate(-308.000000, -690.000000)",
                "fill": "#174165",
                "fillRule": "nonzero"
              },
              [
                "g",
                "Group-3",
                [],
                {
                  "transform": "translate(66.000000, 75.000000)"
                },
                [
                  "g",
                  "Group-Copy",
                  [],
                  {
                    "transform": "translate(110.000000, 429.000000)"
                  },
                  [
                    "g",
                    "Group-2",
                    [],
                    {
                      "transform": "translate(128.000000, 186.000000)"
                    },
                    [
                      "path",
                      "!",
                      [],
                      {
                        "d": "M13.5652174,6 L16.4347826,6 L16.4347826,10.0908203 L15.9602582,13 L14.1082817,13 L13.5652174,10.0638428 L13.5652174,6 Z M13.5652174,14 L16.4347826,14 L16.4347826,17 L13.5652174,17 L13.5652174,14 Z M14.5217391,2 C9.76728781,2 5.91304348,6.02943725 5.91304348,11 L5.91304348,12 C5.91304348,16.9705627 9.76728781,21 14.5217391,21 L15.4782609,21 C20.2327122,21 24.0869565,16.9705627 24.0869565,12 L24.0869565,11 C24.0869565,6.02943725 20.2327122,2 15.4782609,2 L14.5217391,2 Z M14.5217391,0 L15.4782609,0 C21.2892569,0 26,4.92486775 26,11 L26,12 C26,18.0751322 21.2892569,23 15.4782609,23 L14.5217391,23 C8.71074307,23 4,18.0751322 4,12 L4,11 C4,4.92486775 8.71074307,0 14.5217391,0 Z"
                      }
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ],
      [
        "div",
        "textContainer",
        [],
        {},
        [
          "div",
          "textTitle",
          [],
          {}
        ],
        [
          "div",
          "text",
          [],
          {}
        ]
      ]
    ]
  ],
  "css": {
    "%content": "font-size:14px;margin-top:5px;font-family:\"Helvetica Neue\", \"HelveticaNeueW01-55Roma\", \"HelveticaNeueW02-55Roma\", \"HelveticaNeueW10-55Roma\", Helvetica, Arial, sans-serif;line-height:1.43;",
    "%content > div": "padding-left:17px;",
    "%textContainer": "word-wrap:break-word;max-width:206px;color:#174165;",
    "%textTitle": "font-weight:500;",
    "%text": "font-weight:300;"
  }
}
 skins['wysiwyg.viewer.skins.TPAWidgetNativeSkin'] = {
  "react": [],
  "exports": {
    "TPAWidgetNativeDeadcomp": {
      "skin": "wysiwyg.viewer.skins.TPAWidgetNativeDeadcompSkin"
    }
  },
  "css": {}
}
 skins['wysiwyg.viewer.skins.TPAWidgetSkin'] = {
  "react": [
    [
      "iframe",
      "iframe",
      [],
      {}
    ],
    [
      "div",
      "overlay",
      [],
      {}
    ]
  ],
  "exports": {
    "preloaderOverlay": {
      "skin": "wysiwyg.viewer.skins.TPAPreloaderSkin",
      "styleGroup": "preloaderStyleGroup"
    },
    "unavailableMessageOverlay": {
      "skin": "wysiwyg.viewer.skins.TPAUnavailableMessageOverlaySkin",
      "styleGroup": "tpaUnavailableMessageOverlaySkinStyleGroup"
    }
  },
  "css": {
    "%": "overflow:hidden;",
    "% iframe": "position:absolute;width:100%;height:100%;overflow:hidden;"
  }
}

    return skins;
});

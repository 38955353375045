/* eslint-disable */
define(['santa-components', 'react', 'tpaComponents/mixins/tpaPreviewEditorMixin', 'componentsCore'], function(santaComponents, React, tpaPreviewEditorMixin, componentsCore) {
    'use strict';

    const compRegistrar = componentsCore.compRegistrar;
    /* eslint-enable */

    /**
     * @class components.TPAUnresponsive
     * @extends {tpa.mixins.tpaCompBase}
     */
    const tpaPreloader = Object.freeze({
        displayName: 'TPAUnavailableMessageOverlay',
        mixins: [componentsCore.mixins.skinBasedComp, tpaPreviewEditorMixin],
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },

        getInitialState() {
            return {
                showOverlay: true
            };
        },

        getSkinProperties() {
            return {
                text: {
                    children: [this.props.compData.text]
                },
                dismissButton: {
                    onClick: this.props.compData.hideOverlayFunc
                },
                reloadButton: {
                    children: [this.props.compData.reload],
                    onClick: this.props.compData.reloadFunc
                },
                openHelp: this.openHelp
            };
        }
    });

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay', tpaPreloader);
});

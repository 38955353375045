define(['lodash', 'coreUtils', 'tpaComponents/utils/tpaUtils'], function (_, coreUtils, tpaUtils) {
    'use strict';

    const getMainSectionWidgetData = coreUtils.clientSpecMapUtils.getMainSectionWidgetData;

    const isSuperAppByCompId = function (siteAPI, compId) {
        const appData = tpaUtils.getAppData(siteAPI, compId);
        return _.get(appData, 'isWixTPA');
    };

    const isFullTpaPageResponsiveApp = function (siteData) {
        const currentPage = siteData.getCurrentUrlPageId();
        const pageDataItem = siteData.getDataByQuery(currentPage);

        if (pageDataItem && pageDataItem.type === 'Page' && pageDataItem.tpaApplicationId > 0) {
            return isFullPageOnMobile(siteData, pageDataItem.tpaApplicationId, pageDataItem.tpaPageId);
        }

        return false;
    };

    const isFullPageOnMobile = function (siteData, tpaApplicationId, tpaPageId) {
        const appData = siteData.getClientSpecMap()[tpaApplicationId];
        if (appData) {
            const pageWidget = _.find(appData.widgets, widget => widget.appPage && widget.appPage.id === tpaPageId);
            if (pageWidget) {
                return pageWidget.shouldBeStretchedByDefaultMobile || pageWidget.appPage.landingPageInMobile || pageWidget.appPage.fullPage;
            }
        }

        return false;
    };

    const isCurrentPageFullResponsive = function (siteData) {
        return siteData.isMobileDevice() && siteData.isMobileView() && !!isFullTpaPageResponsiveApp(siteData);
    };

    const getAppDataByAppDefinitionId = function (siteData, appDefinitionId) {
        const clientSpecMap = siteData.getClientSpecMap();
        return _.find(clientSpecMap, {appDefinitionId});
    };

    const getWidgetDataByTpaPageId = function (appData, sectionId) {
        const widgets = _.get(appData, 'widgets');
        return _.find(widgets, widget => _.get(widget, 'appPage.id') === sectionId);
    };

    const isFullPage = function (compData, appData) {
        if (appData) {
            const widgetId = _.get(compData, 'widgetId');
            const widgetData = widgetId ? _.get(appData.widgets, widgetId) : getMainSectionWidgetData(appData);
            return _.get(widgetData, 'appPage.fullPage', false);
        }
        return false;
    };

    const isFullPageDesktopOnly = function (compData, appData) {
        if (appData) {
            const widgetId = _.get(compData, 'widgetId');
            const widgetData = widgetId ? _.get(appData.widgets, widgetId) : getMainSectionWidgetData(appData);
            return _.get(widgetData, 'componentFields.fullPageDesktopOnly', false);
        }
        return false;
    };

    function getNativeComponentUrl(siteData, applicationId, widgetId) {
        const clientSpecMap = siteData.getClientSpecMap();
        if (applicationId && !widgetId) {
            const appData = clientSpecMap[applicationId];
            widgetId = _.get(getMainSectionWidgetData(appData), 'widgetId');
        }
        const queryParams = siteData.getQueryParams();
        const scriptsLocation = siteData.serviceTopology.staticServerUrl;
        const widgetsOverrideQueryParam = coreUtils.scriptsOverridesService.getWidgetsOverride(queryParams);
        if (widgetsOverrideQueryParam) {
            const urlOverride = coreUtils.scriptsOverridesService.getUrlOverrideForWidget(widgetId, widgetsOverrideQueryParam, scriptsLocation);
            if (urlOverride) {
                return urlOverride;
            }
        }
        const componentUrl = _.get(clientSpecMap[applicationId].widgets[widgetId], 'componentFields.componentUrl');
        if (!componentUrl) {
            return null;
        }
        return _.endsWith(componentUrl, '.js') ? componentUrl : `${componentUrl}.js`;
    }


    return {
        getAppDataByAppDefinitionId,
        getMainSectionWidgetData,
        isSuperAppByCompId,
        isCurrentPageFullResponsive,
        getWidgetDataByTpaPageId,
        isFullPage,
        isFullPageDesktopOnly,
        getNativeComponentUrl
    };
});

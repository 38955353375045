/* eslint-disable */
define([
    'coreUtilsLib',
    'componentsCore',
    'santa-components',
    'tpaComponents/mixins/tpaCompBaseMixin',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/mixins/tpaWidgetMixin',
    'tpaComponents/mixins/tpaResizeWindowMixin'

], function (coreUtilsLib, componentsCore, santaComponents, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaWidgetMixin, tpaResizeWindowMixin) {
    'use strict';
    /* eslint-enable */

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPAPreloader
     * @extends {componentsCore.skinBasedComp}
     * @extends {tpa.mixins.tpaCompBase}
     */
    const tpaGluedWidget = {
        displayName: 'TPAGluedWidget',
        statics: {
            behaviors: {
                notifyWidget: {methodName: 'notifyWidgetInternal', params: ['action']}
            }
        },
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaWidgetMixin, tpaResizeWindowMixin],
        isZeroHeightAllowed() {
            return true;
        },
        mutateIframeUrlQueryParam(queryParamsObj) {
            queryParamsObj.width = this.state.initialWidth; // eslint-disable-line santa/no-side-effects

            return queryParamsObj;
        },
        mutateSkinProperties(skinProps) {
            skinProps['']['data-has-ads'] = this.props.shouldShowWixAds;
            return skinProps;
        },
        notifyWidgetInternal(actionName) {
            this.sendPostMessage({
                intent: 'addEventListener',
                eventType: 'QUICK_ACTION_TRIGGERED',
                params: {
                    quickAction: actionName
                }
            });
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAGluedWidget', tpaGluedWidget);
    return tpaGluedWidget;
});
